import React, { createContext, useState, useContext, useEffect } from "react";
import { fetchContent } from "../actions/content";

const ContentContext = createContext();

export const ContentProvider = ({ children }) => {
  const [textSearch, setTextSearch] = useState({
    query: "",
    timestamp: "",
  });
  const [textSearchParams, setTextSearchParams] = useState({
    c_name: true,
    address: true,
    website: true,
  });
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [perPage, setPerPage] = useState(25);

  const [result, setResult] = useState();
  const [fetching, setFetching] = useState(true);
  const [selectedIds, setSelectedIds] = useState([]);
  const [chosenItems, setChosenItems] = useState([]);

  const [filters, setFilters] = useState({
    region: [],
    country: [],
    province: [],
    size: [],
    products: [],
    type: [],
    // 'only_importers': false,
    years: [],
    employees: [],
    prodq: [],
    tier: [],
    only_emails: false,
    prod_country: [],
    prod_strict: false,
    time_added: "0",
    time_updated: "0",
    folder: "",
  });

  function clearFilters() {
    // Filters object
    setFilters({
      region: [],
      country: [],
      province: [],
      size: [],
      products: [],
      type: [],
      years: [],
      employees: [],
      prodq: [],
      tier: [],
      // 'only_importers': false,
      only_emails: false,
      prod_country: [],
      prod_strict: false,
      time_added: "0",
      time_updated: "0",
      folder: "",
    });

    // Folder and filters
    setTextSearch({
      query: "", // string
      timestamp: "", // string
    });
    setTextSearchParams({
      c_name: true,
      address: true,
      website: true,
    });
    setSelectedCountries([]);
    setPageNumber(1);
  }

  const [requestParams, setRequestParams] = useState({
    filters: filters,
    time_filter: "0",
    text_search: textSearch,
    text_search_params: textSearchParams,
    per_page: perPage,
    page_number: pageNumber,
    user_expired: false,
  });

  useEffect(() => {
    setRequestParams({
      ...requestParams,
      filters,
      text_search: textSearch,
      text_search_params: textSearchParams,
      page_number: pageNumber,
      per_page: perPage,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, textSearch, pageNumber, perPage]);

  function requestItems() {
    fetchContent(requestParams).then((data) => {
      setResult(data);
      setFetching(false);
    });
  }

  useEffect(() => {
    requestItems();
    setFetching(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestParams]);

  useEffect(() => {
    setChosenItems(selectedIds);
  }, [selectedIds]);

  function selectOneItem(id) {
    if (selectedIds?.includes(id)) {
      const newChosenItems = selectedIds.filter((item) => item !== id);
      setSelectedIds(newChosenItems);
    } else {
      setSelectedIds((prevState) => [...prevState, id]);
    }
  }

  function selectAllItems(type) {
    // console.log(type);
  }

  function clearChosenItems() {
    setChosenItems([]);
  }

  return (
    <ContentContext.Provider
      value={{
        filters,
        setFilters,
        clearFilters,
        pageNumber,
        selectedCountries,
        textSearch,
        textSearchParams,
        setSelectedCountries,
        setPageNumber,
        setTextSearch,
        setTextSearchParams,
        perPage,
        setPerPage,
        result,
        fetching,
        selectOneItem,
        selectAllItems,
        selectedIds,
        chosenItems,
        setChosenItems,
        clearChosenItems,
        requestParams
      }}
    >
      {children}
    </ContentContext.Provider>
  );
};

export const useContent = () => {
  return useContext(ContentContext);
};
