import { getToken } from "../utils";
import Api from "../utils/api";

export async function fetchContent(request_params) {
  try {
    const response = await Api.post("distributors", {
      data: "distributors",
      token: getToken(),
      request_params,
    });
    // if (response.data.token_code !== process.env.REACT_APP_CODE) {
    //   alert("ERROR Content request. Wrong token code. с100");
    //   window.location.replace("https://vinaty.com/login/");
    // }
    return response.data;
  } catch (error) {
    // alert("ERROR Content request. Wrong token code. d001");
    console.error(error);
  }
}

export async function fetchData(params) {
  try {
    const response = await Api.post("company", {
      data: "get_data",
      token: getToken(),
      ...params,
    });
    return response.data;
  } catch (error) {
    alert(
      "Please report this error code to support@vinaty.com. Error code d902"
    );
  }
}

export async function reportData(params) {
  try {
    const response = await Api.post("distributors", {
      data: "user_report",
      token: getToken(),
      ...params,
    });
    return response.data;
  } catch (error) {
    alert(
      "Please report this error code to support@vinaty.com. Error code d903"
    );
  }
}

export async function handleSaveNote(params) {
  try {
    const response = await Api.post("folders", {
      data: "save_note",
      token: getToken(),
      ...params,
    });
    return response.data;
  } catch (error) {
    alert(
      "Please report this error code to support@vinaty.com. Error code d904"
    );
  }
}
