import React from "react";

export default function DBUserItem(props) {
  const { new_users } = props;

  return new_users?.map((item, index) => {
    return (
      // item.status === 'wc-completed' ?
      <div className="dashboard__item" key={item.customer_id + index}>
        <div className="dashboard__ids column-value">{item.customer_id}</div>

        <div className="dashboard__emails column-value">
          <p>{item.billing_email ? item.billing_email : "no billing email"}</p>
          <p>
            {item.vinaty_user_data?.ip ? item.vinaty_user_data?.ip : "no ip"}
          </p>
        </div>

        <div className="dashboard__status column-value">
          {item.all_orders?.map((order) => {
            return (
              // <>
              <span
                className={
                  "status__label" +
                  (order.status === "wc-completed"
                    ? " status__label--green"
                    : " status__label--red")
                }
                key={order.date_created_gmt + "-product"}
              >
                {order.products[0]}
                <span className="status__label-payment">-- {order.status}</span>
              </span>
              // </>
            );
          })}

          <span className="status__dates">
            <span>Rg -- {item.user_registered?.toString().slice(0, 10)}</span>
            <span>St -- {item.date_created_gmt?.slice(0, 10)}</span>
            <span>En -- Unknown</span>
          </span>
        </div>

        <div className="dashboard__date column-value">
          <span>
            {item.vinaty_user_data?.last_visit
              ? new Date(item.vinaty_user_data.last_visit * 1000)
                  .toISOString()
                  .replace("T", " ")
                  .slice(0, 19)
              : "Invalid date"}
          </span>
        </div>

        <div className="dashboard__calc column-value">
          <div>
            <span>Начислено:</span>
            <span>{" " + item.vinaty_user_data?.credited_total}</span>
          </div>
        </div>

        <div className="dashboard__downloads column-value">
          <div>
            <span>{`${item.vinaty_user_data?.credits}`}</span>
            <p>
              <span className="downloads__h-total-used">Скачано:</span>
              <span className="downloads__total-used">
                {" "}
                {item.vinaty_user_data?.download_ids?.split(",").length - 1 ===
                "0"
                  ? item.vinaty_user_data?.download_ids?.split(",").length - 1
                  : item.vinaty_user_data?.download_ids?.split(",").length -
                    1 +
                    1}
              </span>
            </p>
          </div>
        </div>

        <div className="dashboard__open-links column-value">
          <div>
            <span>{item.vinaty_user_data?.link_open}</span>

            <p>
              <span className="open-links__h-total-used">Просмотры:</span>
              <span className="open-links__total-used">
                {item.vinaty_user_data?.uniq_link_open.split(",").length
                  ? item.vinaty_user_data?.uniq_link_open.split(",").length - 1
                  : "No data"}
              </span>
            </p>
          </div>
        </div>

        <div className="dashboard__searches column-value">
          {item.vinaty_user_data?.searches}
        </div>
      </div>
      // : null
    );
  });
}
