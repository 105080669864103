import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";

// Components
import Content from "../../components/Content";
import Heading from "../../components/Heading";
import Search from "../../components/Search";
import Filters from "../../components/Filters";
import Sidebar from "../../components/Sidebar";
import PaginationPagination from "../../components/Pagination/PaginationPagination";
import PaginationTotalResults from "../../components/Pagination/PaginationTotalResults";
import Dashboard from "../../Dashboard";
import UpdatesList from "../../components/UpdatesList";
import ContentMenu from "../../components/Pagination/ContentMenu";
import Footer from "../../components/Footer/";

// Materials
import PercentIcon from "@mui/icons-material/Percent";
import TrustPilot from "../../components/TrustPilot";
import LimitViews from "../../components/LimitViews";
import { ClosedStorefront } from "../ClosedStorefront";
import MainLoader from "../../components/MainLoader";
import { useUser } from "../../hooks/UserContext";
import { useContent } from "../../hooks/ContentContext";
import { getToken } from "../../utils";

const MainNavigation = () => {
  // Show/Hide Feedback
  const { userDetails, preLoader, admin, setAdmin, setDefender } = useUser();
  const { chosenItems, setChosenItems, result } = useContent();
  // Prevent load if no cookies, design only, not a real security
  const [closedScreen, setClosedScreen] = useState(true);
  const [wrongToken, setWrongToken] = useState(false);

  // CHECKER COOKIES //
  const cookies = new Cookies();

  // Multiple choser of items in pagination
  const [allChosen, setAllChosen] = useState(false);

  // State to hide all the details for Trial plan

  // Selected All distributors
  const [selectedAll, setSelectedAll] = useState(false);

  // State to show or disable Feedback form in heading
  // const [feedbackUserStatus,setFeedbackUserStatus] = useState(0);

  // Current Folder
  const [currentFolder, setCurrentFolder] = useState("");

  // Check if server production or local dev //
  var testServer = process.env.REACT_APP_SERVER;

  // Update list state > Filters tp UpdatesList
  const [updateArrState, setUpdateArrState] = useState([]);

  // NOTIFICATION FROM ADMIN
  const [notificationState, setNotificiationState] = useState([]);

  // NOTIFICATION DISCOUNT
  const [discountState, setDiscountState] = useState([]);

  // Displayed Items IDS
  const [resultIds, setResultIds] = useState([]);

  // Trigger State to Download single item from Multi
  const [singleDownloadState, setSingleDownloadState] = useState(0);

  const [chosenOnThisPage, setChosenOnThisPage] = useState([]);

  // Counter of user Bookmarks - 21.08.24
  const [bookmarksCounter, setBookmarksCounter] = useState(0);

  // Download summary open
  const [modalDownload, setModalDownload] = useState(false);

  // Dashboard manual enable
  const [adminDashboard, setAdminDashboard] = useState(false);

  useEffect(() => {
    // Получаем текущий URL
    const url = new URL(window.location);

    // Удаляем параметры, связанные с Google Tag Manager или Analytics
    url.searchParams.delete("_gl");
    url.searchParams.delete("_gcl_au");

    // Обновляем URL без перезагрузки страницы
    window.history.replaceState({}, "", url);
  }, []);

  function enableAdminDashboard() {
    setAdminDashboard((adminDashboard) => !adminDashboard);
  }

  // Disable Preloader
  useEffect(() => {
    if (!preLoader) {
      document.querySelector("body").style.overflow = "auto";
    }
  }, [preLoader]);

  //\\//\\// Check cookies in the user browser \\//\\//\\
  function getCookie() {
    const token = getToken();
    if (token){
      setClosedScreen(false);
    }else{
      setClosedScreen(true);
      setWrongToken(true);
    }
  }

  useEffect(() => {
    getCookie();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO Dmitry
  // useEffect(() => {
  //   if (userExpired === true) {
  //     setRequestParams((prevState) => ({
  //       ...prevState, // keep all other key-value pairs
  //       user_expired: userExpired, // update the value of specific key
  //     }));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [userExpired]);

  // Answer of the server with distributors and info

  // Pagination user choose how many item to show, max 100 on server limit
  const [perPage, setPerPage] = useState(25); // string number

  const [pageNumber, setPageNumber] = useState(1); // string number current start page

  const [currencies, setCurrencies] = useState("");
  // const [result, setResult] = useState([]);
  useEffect(() => {
    UpdateChosenItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenItems]);

  function UpdateChosenItems() {
    setChosenOnThisPage([]);
    setAllChosen(false);
    if (chosenItems.length > 0 && chosenItems.length >= resultIds.length) {
      if (resultIds.every((value) => chosenItems.includes(value))) {
        setAllChosen(true);
      }
    } else {
      setAllChosen(false);
    }
  }

  // When in Item Click Download
  function SingleDownload(item_id) {
    setSingleDownloadState(item_id);
    setChosenItems([item_id]);
  }

  useEffect(() => {
    UpdateChosenItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultIds]);

  // Change manually bookmark counter
  function changeBookmarkCounter(value) {
    var tempCounter = bookmarksCounter + value;
    setBookmarksCounter(tempCounter);
  }

  return (
    <>
      {!closedScreen ? (
        admin && adminDashboard ? (
          <div className="dashboard">
            <Dashboard
              setAdmin={setAdmin}
              setDefender={setDefender}
              getCookie={getCookie}
              user={userDetails["user_id"]}
            />
          </div>
        ) : (
          <div
            className={
              "main" +
              (preLoader ? " main--disabled" : "") +
              (notificationState.length > 0 &&
              Array.isArray(notificationState) &&
              notificationState.some((item) => item.id === "11")
                ? " main--notification"
                : "")
            }
          >
            {preLoader ? <MainLoader /> : null}

            {notificationState.length > 0 &&
            Array.isArray(notificationState) &&
            notificationState.some((item) => item.id === "11")
              ? (() => {
                  const item = notificationState.find(
                    (item) => item.id === "11"
                  );
                  return (
                    <div className="main__notification">
                      <div className="notification__heading">
                        {item.heading}
                      </div>
                      <div className="notification__text">{item.text}</div>
                    </div>
                  );
                })()
              : null}

            <Sidebar
              enableAdminDashboard={enableAdminDashboard}
              bookmarksCounter={bookmarksCounter}
            />
            <div className="content">
              <LimitViews />
              <Heading discountState={discountState} />

              <div className="header">
                <div className="underheading">
                  <Search />
                  <UpdatesList updateArrState={updateArrState} />

                  {userDetails["user_plan"] !== 4 &&
                  userDetails["user_plan"] !== 5 ? (
                    <TrustPilot />
                  ) : null}

                  {notificationState.length > 0 &&
                  (userDetails["user_plan"] === 4 ||
                    userDetails["user_plan"] === 5) &&
                  Array.isArray(notificationState) &&
                  notificationState.some((item) => item.id === "14")
                    ? (() => {
                        const item = notificationState.find(
                          (item) => item.id === "14"
                        );
                        return (
                          <div className="trial__notification" key={item.id}>
                            <div>
                              <PercentIcon />
                            </div>
                            <div className="trial__text">{item.text}</div>
                          </div>
                        );
                      })()
                    : null}
                </div>

                <Filters
                  setUpdateArrState={setUpdateArrState}
                  setNotificiationState={setNotificiationState}
                  setDiscountState={setDiscountState}
                  setCurrencies={setCurrencies}
                />
              </div>

              <div className="pagination">
                <div className="pagination__buttons">
                  <PaginationTotalResults />
                </div>

                <PaginationPagination
                  perPage={perPage}
                  setPerPage={setPerPage}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  totalResult={
                    result?.result_counted !== undefined
                      ? result.result_counted
                      : false
                  }
                />
              </div>

              <ContentMenu
                setChosenOnThisPage={setChosenOnThisPage}
                selectedAll={selectedAll}
                foundResult={
                  result?.result_counted !== undefined
                    ? result.result_counted
                    : false
                }
                currentFolder={currentFolder}
                resultIds={resultIds}
                getCookie={getCookie}
                chosenOnThisPage={chosenOnThisPage}
                allChosen={allChosen}
                setSelectedAll={setSelectedAll}
                setAllChosen={setAllChosen}
                singleDownloadState={singleDownloadState}
                setSingleDownloadState={setSingleDownloadState}
                modalDownload={modalDownload}
                setModalDownload={setModalDownload}
              />

              <div className={"results"}>
                <Content
                  result={result}
                  // setResult={setResult}
                  getCookie={getCookie}
                  allChosen={allChosen}
                  setAllChosen={setAllChosen}
                  pageNumber={pageNumber}
                  currentFolder={currentFolder}
                  setCurrentFolder={setCurrentFolder}
                  setResultIds={setResultIds}
                  SingleDownload={SingleDownload}
                  changeBookmarkCounter={changeBookmarkCounter}
                  setBookmarksCounter={setBookmarksCounter}
                  setModalDownload={setModalDownload}
                  currencies={currencies}
                />
              </div>

              <div className="content-bottom">
                {result?.result_counted > 0 ? (
                  <PaginationPagination
                    totalResult={
                      result?.result_counted !== undefined
                        ? result.result_counted
                        : false
                    }
                  />
                ) : null}
              </div>

              <Footer />

            </div>
          </div>
        )
      ) : (
        <ClosedStorefront wrongToken={wrongToken} />
      )}
    </>
  );
};

export default MainNavigation;
