import React, { useEffect, useState } from "react";

export default function DbReports(props) {
  const { getCookie } = props;

  // const {users,getCookie} = props;

  const [reports, setReports] = useState([]);

  function getReports() {
    var urlApi = process.env.REACT_APP_URL_API + "dashboard";

    const queryBlockUser = {
      data: "get_reports",
      token: getCookie(),
    };

    fetch(urlApi, {
      method: "POST",
      headers: {
        Authorization: process.env.REACT_APP_TOKEN_API,
      },
      cache: "no-store",
      body: JSON.stringify(queryBlockUser),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setReports(data["reports"]);
      })
      .catch((error) => {
        // window.location.replace("https://vinaty.com/login/");
        alert(
          "Something went wrong during save of the content. Error code dashboar d002",
        );
      });
  }

  useEffect(() => {
    getReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="report__t-headings">
        <div className="report__h-ids">Id</div>

        <div className="report__h-company">Company</div>

        <div className="report__h-user">User</div>

        <div className="report__h-date">Date</div>

        <div className="report__h-action">Action</div>
      </div>

      {reports?.map((item, id) => {
        return (
          <div className="report__item" key={id}>
            <div className="report__ids">{item.id}</div>

            <div className="report__company">{item.company}</div>

            <div className="report__user">{item.user_id}</div>

            <div className="report__date">{item.date}</div>

            <div className="report__action">Coming soon</div>
          </div>
        );
      })}
    </>
  );
}
