import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import SettingsIcon from "@mui/icons-material/Settings";
import Tooltip from "@mui/material/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckUser from "../hooks/CheckUser";
import { useUser } from "../hooks/UserContext";

// Refactored

export default function UserInfo() {
  const [daysLeftState, setDaysLeftState] = useState("0");
  const [viewsLeft, setViewsLeft] = useState("0");
  const { userDetails, userCounter, cheat } = useUser();

  useEffect(() => {
    if (
      userDetails !== null &&
      userDetails !== "undefined" &&
      userDetails !== undefined
    ) {
      // set User views from DB
      setViewsLeft(userDetails?.user_views ?? 0);

      // days in plans by id
      const daysInPlan = {
        1: 1,
        4: 1,
        2: 365,
        6: 90,
        7: 180,
        10: 30,
      };

      const currentTime = Math.floor(Date.now() / 1000);
      let daysPlan = 1;
      let startDate = currentTime;

      const userPayments = userDetails.user_payments ?? []; // No user payments = []
      const userPlan = userDetails.user_plan ?? null; // null if no user plan
      const userRegistered = userDetails.user_registered ?? null; // null if no user registation date

      if (userPayments.length > 0) {
        const lastPayment = userPayments[userPayments.length - 1];

        if (
          lastPayment &&
          lastPayment.arm_payment_date &&
          lastPayment.arm_plan_id
        ) {
          startDate = Math.floor(
            new Date(lastPayment.arm_payment_date).getTime() / 1000
          );
          daysPlan = daysInPlan[lastPayment.arm_plan_id] || daysPlan;

          // Additional check of Trial plan
          if (lastPayment.arm_plan_id === "4" && userPlan !== 2) {
            daysPlan = 1;
          }
        } else {
          // No payments - use registation
          if (userRegistered) {
            startDate = Math.floor(new Date(userRegistered).getTime() / 1000);
          }
        }
      }
      const daysLeft = Math.ceil(
        daysPlan - Math.abs(currentTime - startDate) / (60 * 60 * 24)
      );
      setDaysLeftState(daysLeft);
    }
  }, [userDetails]);

  const getButtonLabel = () => {
    const user_plan = userDetails?.user_plan ?? -1;
    const woo_has_purchase = userDetails?.woo_has_purchase ?? false;
    const woo_days_left = userDetails?.woo_days_left ?? -1;

    // Text for user info
    let label = "";
    switch (user_plan) {
      case 0:
        label = "Testing mode";
        break;
      case 1:
        label = "Expired";
        break;
      case 2:
        label = "Full Access";
        break;
      case 4:
        label = "Free Trial";
        break;
      case 5:
        label = "Trial (expired)";
        break;
      default:
        label = "";
        break;
    }

    // Добавляем информацию о сроке действия
    if (woo_has_purchase && woo_days_left > -1) {
      label += ` (Exp. in ${woo_days_left} day${woo_days_left > 1 ? "s" : ""})`;
    } else if (daysLeftState > -1 && user_plan !== 1 && user_plan !== 5) {
      label += ` (Exp. in ${daysLeftState} day${daysLeftState > 1 ? "s" : ""})`;
    }

    return label;
  };

  return (
    <div className={"user-info"}>
      <CheckUser />
      <div className="user-info__welcome">
        <p className="welcome__name">
          Hello, {userDetails?.user_name || "Guest"}
        </p>
      </div>

      <div className="user-info__container-credits">
        <div className="user-info__expired">
          <Button
            href="https://vinaty.com/my-account/"
            target="_blank"
            size="small"
            className="user-info__plan"
            variant="outlined"
            color="primary"
            startIcon={<SettingsIcon />}
            sx={{ textTransform: "none" }}
          >
            {getButtonLabel()}
          </Button>
        </div>

        <div className="user-info__inner">
          <div className="user-info__credits">
            <p className="user-info__counter">
              <span className="user-info__download">
                <DownloadIcon />
              </span>
              <span className="user-info__text-counter">{userCounter}</span>
            </p>
          </div>

          <div className="user-info__views">
            <p className="user-info__counter">
              <span className="user-info__view">
                <VisibilityIcon />
              </span>

              <span id="views" className="user-info__text-counter">
                {viewsLeft}
              </span>
            </p>

            {userDetails["user_plan"] !== 4 ? (
              <Tooltip
                title={
                  <span className="info-span">
                    These are your limits for downloading and viewing companies.
                    Downloads refer to the number of unique companies you can
                    download from the database. Downloads are only counted once,
                    so if you download the same company again, it's free. Views
                    represent the number of times you can open company profiles.
                    Views are also counted only once, meaning you can revisit
                    previously opened companies for free. Downloads and views
                    are credited upfront when you purchase access to the
                    database. You can purchase additional downloads and views,
                    which will be added to your balance. Once your database
                    access period expires, your views and downloads will reset.
                  </span>
                }
                size="medium"
              >
                <IconButton size="small">
                  <HelpOutlineIcon size="small" />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  <span className="info-span">
                    During the trial period, you can download 5 company contacts
                    and view the contacts of 15 companies (each company is
                    counted only once). Re-downloading is free and does not
                    count towards the limit.
                  </span>
                }
                size="medium"
              >
                <IconButton size="small">
                  <HelpOutlineIcon size="small" />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
      </div>

      {cheat === 1 ? (
        <div className="cheat-banner">
          <h2>We noticed that this is a repeated registration.</h2>
          According to the website rules, only one account can be used for the
          trial period. Therefore, Trial Access in the new account has been
          suspended. If you find the Vinaty contact database useful, we
          encourage you to subscribe for full access in your account. If there
          has been an error, please contact us at support@vinaty.com
        </div>
      ) : null}
    </div>
  );
}
