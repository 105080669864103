import React, { useEffect } from "react";
import CustomProviders from "./hooks";
import { MainNavigation } from "./screens/MainNavigation";
import { getToken } from "./utils";

function App() {
  // var salesIqLocal = 'true';
  useEffect(() => {
    getToken();
  }, []);
  const isProduction = process.env.NODE_ENV === "production";
  console.log(`Running in ${isProduction ? "production" : "development"} mode`);
  // limitViews

  return (
    <CustomProviders>
      <MainNavigation />
    </CustomProviders>
  );
}

export default App;
