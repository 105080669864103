import React, { useEffect, useState } from "react";
import DbUsers from "./components/Dashboard/DbUsers";
import DbSearches from "./components/Dashboard/DbSearches";
import DbLost from "./components/Dashboard/DbLost";
import DbReports from "./components/Dashboard/DbReports";
import Button from "@mui/material/Button";
import {
  fetchDashboardStats,
  fetchDublicateEmails,
  handleCopyPhonesToValidate,
  handleUpdateCountryCounter,
} from "./actions/dashboard";

function DashboardFn(props) {
  const { setAdmin, setDefender, getCookie, user } = props;

  // All users list
  const [users, setUsers] = useState([]);

  // All NEW users list
  const [newUsers, setNewUsers] = useState([]);

  // Menu toggle
  const [menu, setMenu] = useState("users");

  const [searchRequests, setSearchRequests] = useState([]);

  // Lost users array
  const [allUsers, setAllUsers] = useState([]);

  // Prevent dublicated load requests to server
  useEffect(() => {
    fetchStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Return to database of distributors
  function menuToggle(aim) {
    if (aim === "database") {
      setAdmin(false);
      setDefender(true);
    } else {
      setMenu(aim);
    }
  }

  // Fetch all user stats
  function fetchStats() {
    fetchDashboardStats()
      .then((data) => {
        setUsers(data["users"]);
        setSearchRequests(data["searches"]);
        setAllUsers(data["reg_users"]);
        setNewUsers(data["new_users"]);
      })
      .catch((error) => {
        console.log(error);
        window.location.replace("https://app.vinaty.com/");
      });
  }

  function dublicateEmails() {
    fetchDublicateEmails()
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function copyPhonesToValidate() {
    handleCopyPhonesToValidate()
      .then((data) => {
        // if (data.token_code === process.env.REACT_APP_CODE) {
        console.log(data);
        // }
        // else {
        //     window.location.replace("https://app.vinaty.com/");
        //     alert('ERROR Content request. Wrong token code. dashboard001');
        //     console.log('react app code ERROR');
        // }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // Обновление счетчиков по странам
  function updateCountryCounter() {
    handleUpdateCountryCounter()
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <div className="dashboard__menu">
        <Button
          className="button-custom-shadow"
          color={menu !== "users" ? "secondary_light" : "secondary"}
          size="large"
          variant="contained"
          onClick={() => menuToggle("users")}
        >
          Show Users
        </Button>

        <Button
          className="button-custom-shadow"
          color={menu !== "reports" ? "secondary_light" : "secondary"}
          size="large"
          variant="contained"
          onClick={() => menuToggle("reports")}
        >
          Reports
        </Button>

        <Button
          className="button-custom-shadow"
          color={menu !== "searches" ? "secondary_light" : "secondary"}
          size="large"
          variant="contained"
          onClick={() => menuToggle("searches")}
        >
          Show Searches
        </Button>

        <Button
          className="button-custom-shadow"
          color={menu !== "lost" ? "secondary_light" : "secondary"}
          size="large"
          variant="contained"
          onClick={() => menuToggle("lost")}
        >
          Lost
        </Button>

        <Button
          className="button-custom-shadow"
          color="secondary_light"
          size="large"
          variant="contained"
          onClick={() => menuToggle("database")}
        >
          Return to Data Base
        </Button>

        {user === 1222 ? (
          <>
            <Button
              className="button-custom-shadow"
              color="red"
              size="large"
              variant="contained"
              onClick={() => updateCountryCounter()}
            >
              Обновить счетчики
            </Button>

            <Button
              className="button-custom-shadow"
              color="red"
              size="large"
              variant="contained"
              onClick={() => copyPhonesToValidate()}
            >
              Добавить телефоны
            </Button>

            <Button
              className="button-custom-shadow"
              color="red"
              size="large"
              variant="contained"
              onClick={() => dublicateEmails()}
            >
              Добавить имейлы
            </Button>
          </>
        ) : null}
      </div>

      <div className="dashboard__content">
        <div className="dashboard__heading">{menu}</div>

        {menu === "users" ? (
          <DbUsers new_users={newUsers} users={users} getCookie={getCookie} />
        ) : menu === "searches" ? (
          <DbSearches searchRequests={searchRequests} />
        ) : menu === "lost" ? (
          <DbLost users={allUsers} activeUsers={users} />
        ) : menu === "reports" ? (
          <DbReports getCookie={getCookie} />
        ) : (
          "loading"
        )}
      </div>
    </>
  );
}
export default DashboardFn;
