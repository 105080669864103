import React, { useEffect, useState } from "react";

export default function DbUsers(props) {
  const { users, activeUsers } = props;

  const [usersIds, setUserIds] = useState([]);

  var users_ids = [];

  useEffect(() => {
    for (let i = 0; i < activeUsers.length; i++) {
      users_ids.push(activeUsers[i]["id"]);
      setUserIds(users_ids);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  return (
    <>
      <div className="dashboard__t-headings">
        <div className="dashboard__">Id</div>

        <div className="dashboard__">Emails</div>

        <div className="dashboard__">Registered</div>
      </div>

      {users?.map((item, id) => {
        if (!usersIds.includes(item["ID"])) {
          return (
            <div className={"dashboard__item"} key={id}>
              <div className="dashboard__ids column-value">{item["ID"]}</div>

              <div className="dashboard__emails column-value">
                {item["user_email"]}
              </div>

              <div className="dashboard__date column-value">
                {item["user_registered"]}
              </div>
            </div>
          );
        } else {
          return <div key={id}>Define</div>;
        }
      })}
    </>
  );
}
