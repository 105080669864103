import React from "react";

import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

export default function DbUsers(props) {
  const { searchRequests } = props;

  function toggleChildSearches(event) {
    var thistItem = event.currentTarget.parentNode;
    var idItem = thistItem.dataset.user;
    var treeNode = document.querySelector(".dashboard__content");
    var childEls = treeNode.querySelectorAll(`[data-parent='${idItem}']`);
    for (let i = 0; i < childEls.length; i++) {
      if (
        childEls[i].dataset.status_search === "true" &&
        childEls[i].classList.contains("item-search--hidden")
      ) {
        childEls[i].classList.remove("item-search--hidden");
      } else {
        childEls[i].classList.add("item-search--hidden");
      }
    }
  }

  return (
    <>
      {searchRequests?.map((item, index, arr) => {
        var date = "Not defined";
        date = new Date(item.time * 1000).toLocaleDateString("en-US");

        // Detect parent or child
        var currentUser = item.user_id;
        var prevUser = "0";
        if (index !== 0) {
          prevUser = arr[index - 1].user_id;
        }
        var child = false;
        if (currentUser === prevUser) {
          child = true;
        }

        // Exclude our searches
        if (item.user_id !== "42" && item.user_id !== "251") {
          return (
            <div
              data-user={item.user_id}
              data-status_search={child}
              data-parent={child ? prevUser : 0}
              className={
                "dashboard__item item-search" +
                (child ? " item-search--child item-search--hidden" : "")
              }
              key={index}
            >
              <div className="search__userid column-value">{item.user_id}</div>

              <div className="search__email column-value">{item.email}</div>

              <div className="search__region column-value">{item.region}</div>

              <div className="search__country column-value">{item.country}</div>

              <div className="search__province column-value">
                {item.province}
              </div>

              <div className="search__type column-value">{item.type}</div>

              <div className="search__size column-value">{item.size}</div>

              <div className="search__prod_country column-value">
                {item.prod_country}
              </div>

              <div className="search__date column-value">{date}</div>

              {!child ? (
                <Button
                  className="search__expand button-custom-shadow button-expand-searches"
                  color={"secondary_light"}
                  size="large"
                  variant="contained"
                  onClick={(event) => toggleChildSearches(event)}
                  endIcon={<AddCircleOutlineIcon />}
                >
                  Show/Hide Child Searches
                </Button>
              ) : null}
            </div>
          );
        } else {
          return null;
        }
      })}
    </>
  );
}
