import { getToken } from "../utils";
import Api from "../utils/api";

export async function prepareData(item) {
  try {
    const response = await Api.post("distributors", {
      data: "single-download",
      token: getToken(),
      item,
    });
    // if (response.data.token_code !== process.env.REACT_APP_CODE) {
    //   alert("ERROR Content request. Wrong token code. p004");
    //   // window.location.replace("https://vinaty.com/login/");
    //   return [];
    // }
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}
