import { getToken } from "../utils";
import Api from "../utils/api";

export async function fetchDashboardStats() {
  try {
    const response = await Api.post("dashboard", {
      data: "dashboard_stats",
      token: getToken(),
    });
    if (response.data.token_code !== process.env.REACT_APP_CODE) {
      window.location.replace("https://app.vinaty.com/");
      alert("ERROR Content request. Wrong token code. dashboard001");
      throw new Error("react app code ERROR");
    }
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function fetchDublicateEmails() {
  try {
    const response = await Api.post("dashboard", {
      data: "dublicate_emails",
      token: getToken(),
    });
    if (response.data.token_code !== process.env.REACT_APP_CODE) {
      window.location.replace("https://app.vinaty.com/");
      alert("ERROR Content request. Wrong token code. dashboard002");
      throw new Error("react app code ERROR");
    }
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function handleCopyPhonesToValidate() {
  try {
    const response = await Api.post("dashboard", {
      data: "copy_phones",
      token: getToken(),
    });
    if (response.data.token_code !== process.env.REACT_APP_CODE) {
      window.location.replace("https://app.vinaty.com/");
      alert("ERROR Content request. Wrong token code. dashboard003");
      throw new Error("react app code ERROR");
    }
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function handleUpdateCountryCounter() {
    try {
        const response = await Api.post("dashboard", {
          data: "count_countries",
          token: getToken(),
        });
        if (response.data.token_code !== process.env.REACT_APP_CODE) {
          window.location.replace("https://app.vinaty.com/");
          alert("ERROR Content request. Wrong token code. dashboard003");
          throw new Error("react app code ERROR");
        }
        return response.data;
      } catch (error) {
        console.error(error);
      }
}